import { UserBasicDto, UserDto } from "api-shared";
import { CellProps } from "react-table";
import UserEntryWithPopup from "../user/UserEntryWithPopup";

interface ITableUserCellProps<D extends object = Record<string, unknown>> extends CellProps<D> {
    users?: UserDto[] | null;

    /**
     * User to display. If a number is passed, the user is resolved using the users prop
     *
     * @type {(UserDto | number | null | undefined)}
     * @memberof ITableUserCellProps
     */
    value: UserBasicDto | number | null | undefined;
}

const TableUserCell = <D extends object = Record<string, unknown>>({ value, users }: ITableUserCellProps<D>) => {
    if (value == null) {
        return null;
    }

    if (typeof value === "number" && users == null) {
        // not able to resolve the user, show the id instead
        return <>{value}</>;
    }
    const user = typeof value === "number" && users != null ? users.find((u) => u.id === value) : value;
    return typeof user === "object" ? <UserEntryWithPopup inline disableGutters user={user} avatarProps={{ size: 23 }} /> : null;
};

export default TableUserCell;
